<template>
    <div class="d-flex" style="background: #000000; height: 105vh;">
        <img class="mx-auto my-auto" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlO_JDVJs8MR-qe9qNXCIv5IwWFyteAx12vQ&s" alt="">
    </div>
</template>
<script>
import { NoticeBar, Dialog } from 'vant';

export default {
  components: { NoticeBar },
};
</script>
<style scoped>

div {
  position: relative;
  top: 50%;
  margin: -30px auto;
  text-align: center;
}

.glow {
  color: #f00;
  text-shadow: 0px 0px 10px #f00;
}

span {
  display: inline-block;
  padding: 0 10px;
}
</style>
