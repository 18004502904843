<template>
	<div class="app">
		<router-view></router-view>
		<!-- <audio autoplay loop src="http://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/pause.wav"></audio> -->
	</div>
</template>
<script>
	export default {
		name: "app",
		methods: {
			async fetchThreeNumbers() {
				try {
					const res = await axios.post(
						"/threed_numbers",
						{
							limit: "all",
						},
						{
							headers: {
								Authorization: `Bearer ${localStorage.getItem("token")}`,
							},
						}
					);

					this.$store.commit("setThreednumbers", res.data.data);
				} catch (error) {
					console.log(error);
				}
			},

			// checkUserLocation() {
			// 	const country = new Date().toString().split("(")[1].split(" ")[0];
			// 	if (country !== "Myanmar") {
			// 		window.location.replace("https://555mix.vercel.app/");
			// 	}
			// },
		},
		mounted() {
			this.fetchThreeNumbers();
			// this.checkUserLocation();
		},
	};
</script>
